.table-container {
    display: flex;
    justify-content: center;
    /* padding: 20px; */
}

.nutrition-assessment-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
}

.nutrition-assessment-table th,
.nutrition-assessment-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.nutrition-assessment-table thead th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.label-cell {
    background-color: #E8FDFA;
    /* Yellow background color */
    font-weight: bold;
    text-align: left;
}

.nutrition-assessment-table td[colspan="6"] {
    background-color: #f9f9f9;
}

.nutrition-assessment-table th[rowspan="2"] {
    width: 200px;
}


/* tooltip */
.tooltip-container {
    left: -380px;
    top: -107px;
    position: absolute;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    padding: 10px;
    min-width: 300px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
}

.tooltip-container::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
}

.tooltip-container table {
    width: 100%;
    border-collapse: collapse;
}

.tooltip-container th,
.tooltip-container td {
    text-align: left;
    padding: 6px 8px;
}

.tooltip-container th {
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}

.custom_tooltip_arrow {
    position: absolute;
    bottom: -6px;
    color: white;
    right: -3px;
}

.icon_position {
    position: absolute;
    right: 92px;
    bottom: 49px;
}

.outer-circle {
    width: 200px;
    height: 200px;
    background-color: #f0f0f0;
    /* Outer circle color */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.inner-circle {
    width: 198px;
    height: 198px;
    background-color: #EFF4F5;
    /* Full red background */
    border-radius: 50%;
    position: absolute;
    top: 49.3%;
    /* Center vertically */
    left: 50.08%;
    /* Center horizontally */
    transform: translate(-50%, -50%);
    /* Adjust to center properly */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

/* Top half content */
.half-circle.top {
    flex: 1;
    /* Take half the space */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #232323;
    /* Text color for top half */
    font-size: 14px;
    font-weight: 500;
}

/* Bottom half content */
.half-circle.bottom {
    flex: 1;
    /* Take half the space */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #232323;
    /* Text color for bottom half */
    font-size: 14px;
    font-weight: 500;
}

/* Divider line */
.divider {
    width: 100%;
    height: 2px;
    /* Thickness of the divider */
    background-color: white;
    /* White divider line */
}
.hyphenated {
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    white-space: normal;
    /* Adjust as needed */
  }



.card-hover-effect {
    border: 1px solid #ddd;
    padding: 10px 20px;
    border-radius: 8px;
    height: 145px;
    width: 214px;
    overflow: hidden;
    background-color: var(--card-background-color);
    /* Default card color */
    transition: all 0.3s ease;
    /* Smooth hover transition */
    position: relative;
}

.card-hover-effect:hover {
    background-color: #13B9A0;
    /* Highlight color on hover */
    color: white;
    /* Change text color on hover */
    transform: translateY(-10px); /* Lift the card */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Intense shadow */
}

.card-hover-effect:hover .trend-icon {
    display: none !important;
}

.card-hover-effect:hover h4 {
    color: white !important;
    /* Ensure text inside card is white */
}
.card-hover-effect:hover .main_value_text {
    color: white !important;
    /* Ensure text inside card is white */
}

.card-hover-effect img {
    transition: filter 0.3s ease;
    /* Smooth transition for the icon */
}

.card-hover-effect:hover img {
    filter: brightness(0) invert(1);
    /* Highlight icon */
}

/* Hidden content that appears on hover */
.on-hover-show {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}

.card-hover-effect:hover .on-hover-show {
    visibility: visible;
    opacity: 1;
}

/* Content that's visible by default */
.on-hover-hide {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.card-hover-effect:hover .on-hover-hide {
    visibility: hidden;
    opacity: 0;
}



.card {
    transition: all 0.4s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
}

/* Styles for 'globaldashboard_card' when hovered */
.globaldashboard_card {
    transform: translateY(-10px); /* Lift the card */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Increased shadow */
    background-color: #13b9a0; /* Change background color */
}

/* Image hover effect for 'globaldashboard_card' */
.globaldashboard_card img {
    filter: brightness(0) invert(1); /* Change image to white */
}

/* Text and label hover effect for 'globaldashboard_card' */
.globaldashboard_card p,
.globaldashboard_card .gender-label {
    color: white; /* Change text color to white */
}

.clinic-select {
    font-family: Arial, sans-serif;
  }
  
  .search-container {
    padding: 8px;
    border-bottom: 1px solid #eaeaea;
    background-color: #f9f9f9;
  }
  
  .search-input {
    width: 100%;
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .select-all-container {
    display: flex;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #eaeaea;
    background-color: #f9f9f9;
  }
  
  .custom-option {
    display: flex;
    align-items: center;
    padding: 8px;
  }
  
  .custom-option:hover {
    background-color: #f0f0f0;
  }
  
  .selected {
    background-color: #f0f0f0;
  }
  
  
  /* Smaller screens */
  .legends-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .legend-item {
    text-align: center;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    line-height: 1.2;
    word-break: break-word;
    font-size: 12px;
    width: 85; /* Default width for large screens */
  }
  
  /* Smaller screen adjustments */
  .custom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Modal Content */
  .custom-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    position: relative;
  }
  
  .modal-row {
    margin-bottom: 10px;
  }
  .svg-preloader {
    fill: red; /* Change the color */
  }