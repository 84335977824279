.tabs-container {
  width: 100%;
  text-align: center;
}

.tab-buttons {
  display: flex;
  justify-content: left;
  margin-bottom: 20px;
}

.tab-button {
  padding: 6px 0px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-family: Poppins;
  color: #3F3F3F;
  border-bottom: 4px solid transparent;
  font-weight: 500;

}

.tab-button.active {
  background-color: transparent;
  border-bottom: 4px solid #13B9A0;
  border-radius: 0px;
  font-size: 14px;
  font-family: Poppins;
  color: #0C0C0C;
  font-weight: 500;
}

.tab-button:hover {
  background-color: transparent;
  border-bottom: 4px solid #13B9A0;
  border-radius: 0px;
  font-size: 14px;
  font-family: Poppins;
  color: #0C0C0C;
  font-weight: 500;
}

.tab-content {
  padding: 0px;
  background-color: transparent;
}

.scrollable-list {
  max-height: 360px;
  /* Ensure it limits height */
  overflow-y: auto;
}

/* Customize scrollbar */
.scrollable-list::-webkit-scrollbar {
  width: 10px;
  /* Adjust width of the scrollbar */
}

.scrollable-list::-webkit-scrollbar-thumb {
  background-color: #13B9A0;
  /* Match the color of your buttons */
  border-radius: 5px;
  /* Rounded corners */
}

.scrollable-list::-webkit-scrollbar-thumb:hover {
  background-color: #13B9A0;
  /* Darker shade on hover */
}

.scrollable-list::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  /* Track background (optional) */
  border-radius: 5px;
}

.custom_dropdown_div_filters {
  width: 200px;
  background-color: white;
  margin-top: 10px;
  position: absolute;
  top: -4px;
  padding-top: 15px;
  z-index: 1000;
  left: -90px;
  border: 1px solid #c2c1c1;
  border-radius: 6px;
  box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
}
.parent-container {
  position: relative;
}
.custom_dropdown_div_filters_ivr_section {
  width: 200px;
  background-color: white;
  margin-top: 10px;
  position: absolute;
  top: -2px;
  padding-top: 15px;
  z-index: 1000;
  left: -90px;
  border: 1px solid #c2c1c1;
  border-radius: 6px;
  box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
}
.custom_dropdown_div_filters_ivr_section_generic {
  width: 163px;
  background-color: white;
  margin-top: 10px;
  position: absolute;
  top: -2px;
  padding-top: 15px;
  z-index: 1000;
  left: -90px;
  border: 1px solid #c2c1c1;
  border-radius: 6px;
  box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
}
.custom_dropdown_div_filters_sms_section {
  width: 200px;
  background-color: white;
  margin-top: 10px;
  position: absolute;
  top: -2px;
  padding-top: 15px;
  z-index: 1000;
  left: -173px;
  border: 1px solid #c2c1c1;
  border-radius: 6px;
  box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
}
.main_dashboard_custom_dropdown_div_filters {
  width: 165px;
  background-color: white;
  margin-top: 10px;
  position: absolute;
  top: -3px;
  padding-top: 15px;
  z-index: 1000;
  left: -84px;
  border: 1px solid #c2c1c1;
  border-radius: 6px;
  box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
}
.case_profile_custom_dropdown_div_filters {
  width: 318px;
  background-color: white;
  margin-top: 10px;
  position: absolute;
  top: 53px;
  padding-top: 15px;
  z-index: 1000;
  right: 29px;
  border: 1px solid #c2c1c1;
  border-radius: 6px;
  box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
}

.custom_view_filters {
  display: block !important;
}
.admission-labels {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px;
  margin-bottom: 0px;
}

.label-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.age-label {
  display: flex;
  justify-content: space-between;
  width: 100px; 
  margin-bottom: 5px;
  font-size: 12px;
}

.main-label {
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  font-family: poppins;
}
.btn-graph-footer {
  background-color: white;
  color: white;
  border: 1px solid #000000;
  font-size: 12px;
  padding: 0px;
  border-top: none;
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.btn-graph-footer:hover {
  background-color: white; /* Change to desired hover color */
  color: white;
}


.gender-bar {
  display: flex;
  justify-content: center;
  align-items: flex-end; 
  gap: 5px; 
}

.gender-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bar {
  width: 8px; 
  border-radius: 10px; 
}

.male-bar {
  height: 22px; 
  background: linear-gradient(to bottom, #dff6ff, #7cb5ec); 
}
.female-bar {
  height: 30px; 
  background: linear-gradient(to bottom, #ffe7f2, #f78fb3); 
}
.height-30px{
  height: 30px !important;
}
.gender-label {
  margin-top: 5px;
  font-size: 10px;
  font-weight: 400;
  color: #999999; 
}

@media (max-width: 991.98px) {
  .custom_view {
    display: block !important;
    ;
  }
}

/* For policyGroup */

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light gray track */
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #13B9A0; /* Teal thumb */
  border-radius: 8px;
  border: 2px solid #f1f1f1; /* Border around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #13B9A0; /* Darker teal on hover */
}


.policy-group-sidebar {
  width: 300px;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  height: 600px; /* Fixed height for scrolling */
  overflow-y: auto;
}

.search-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 12px;
}

.add-button {
  margin-left: 10px;
  padding: 6px 6px;
  background-color: #13B9A0;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  height: 37px;
  width: 41px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  display: flex;
  align-items: left;
 
  padding-bottom: 12px;
  padding-top: 12px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 0px;
  cursor: pointer;
}

li.active {
  background-color: #e6f7f5;
  font-weight: 500;
}

.icon-group {
  margin-left: auto;
  display: flex;
  gap: 10px;
}

.edit-icon, .delete-icon {
  cursor: pointer;
  color: #555;
}

.permissions-container {
  flex: 1;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  height: 600px; /* Fixed height for scrolling */
  overflow-y: auto;
}

.category-section {
  margin-bottom: 20px;
}

label {
  display: block;
  margin: 8px 0;
}

